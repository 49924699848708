<template>
  <v-container>
    <v-row dense justify="center">
      <v-col cols="12" sm="10" md="8">
        <v-row dense>
          <v-col cols="12" sm="6">
            <h2>Kiinteistön tiedot</h2>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <p v-if="cond.name" class="info-wrapper">
              <span class="left">Nimi:</span>
              <strong class="right">{{ cond.name }}</strong>
            </p>
            <p v-if="cond.businessId" class="info-wrapper">
              <span class="left">Y-tunnus:</span>
              <strong class="right">{{ cond.businessId }}</strong>
            </p>
            <p v-if="cond.address" class="info-wrapper">
              <span class="left">Osoite:</span>
              <strong class="right">{{ cond.address }}</strong>
            </p>
            <p v-if="cond.zipCode" class="info-wrapper">
              <span class="left">Postinumero:</span>
              <strong class="right">{{ cond.zipCode }}</strong>
            </p>
            <p v-if="cond.city" class="info-wrapper">
              <span class="left">Postitoimipaikka:</span>
              <strong class="right">{{ cond.city }}</strong>
            </p>
          </v-col>
        </v-row>

        <v-row class="mt-1" dense>
          <v-col cols="12" sm="6">
            <h2>Yhteystiedot</h2>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6" md="4">
            <h3 class="mb-1">Isännöitsijä</h3>
            <p v-if="cond.administrator.companyName">{{ cond.administrator.companyName }}</p>
            <p v-if="cond.administrator.name">{{ cond.administrator.name }}</p>
            <p v-if="cond.administrator.phone">{{ cond.administrator.phone }}</p>
            <p v-if="cond.administrator.email">
              {{ cond.administrator.email }}
              <span
                ><v-icon color="primary" small @click="mailTo([cond.administrator.email])"
                  >mdi-email-send-outline</v-icon
                ></span
              >
            </p>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <h3 class="mb-1">Huoltoyhtiö</h3>
            <p v-if="cond.administrator.propertyMaintenanceCompany">
              {{ cond.administrator.propertyMaintenanceCompany }}
            </p>
            <p v-if="cond.administrator.janitor">{{ cond.administrator.janitor }}</p>
            <p v-if="cond.administrator.janitorPhone">{{ cond.administrator.janitorPhone }}</p>
            <p v-if="cond.administrator.janitorEmail">
              {{ cond.administrator.janitorEmail }}
              <span
                ><v-icon color="primary" small @click="mailTo([cond.administrator.janitorEmail])"
                  >mdi-email-send-outline</v-icon
                ></span
              >
            </p>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <h3 class="mb-1">Kirjanpitäjä</h3>
            <p v-if="cond.administrator.accountantCompany">
              {{ cond.administrator.accountantCompany }}
            </p>
            <p v-if="cond.administrator.accountantName">{{ cond.administrator.accountantName }}</p>
            <p v-if="cond.administrator.accountantPhone">
              {{ cond.administrator.accountantPhone }}
            </p>
            <p v-if="cond.administrator.accountantEmail">
              {{ cond.administrator.accountantEmail }}
              <span
                ><v-icon color="primary" small @click="mailTo([cond.administrator.accountantEmail])"
                  >mdi-email-send-outline</v-icon
                ></span
              >
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixins from "@/mixins/mixins";

export default {
  mixins: [mixins],

  computed: {
    cond() {
      return this.$store.state.condominium.currentCondominium;
    },
  },
};
</script>

<style scoped>
.info-wrapper {
  display: flex;
  max-width: 330px;
}

.left {
  width: 140px;
}

.right {
  flex: 1;
  text-align: right;
}
</style>
